import React from "react"

import Information from "../blocks/Information"
import CryptoBasicsCards from "./cryptoBasicsCards"
import MiddleSectionContainer from "../blocks/MiddleSectionContainer"
import { more } from "./constants"

const App = () => (
  <MiddleSectionContainer special={true} id="common_cryptocurrencies">
    <Information>
      <Information.Title>Common Cryptocurrencies</Information.Title>
      <Information.Content>
        We have compiled a list of the most common cryptocurrencies in
        circulation to help your understanding of the crypto universe
      </Information.Content>

      <CryptoBasicsCards data={more} />
    </Information>
  </MiddleSectionContainer>
)

export default App
