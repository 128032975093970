import styled from "styled-components"
import P from "../../elements/p"

const Content = styled(P)`
  color: #637381;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  max-width: 516px;
  text-align: left;
  background-color: white;
  @media (max-width: 768px) {
    max-width: 100%;
    font-size: 18px;
  }
`
export default Content
