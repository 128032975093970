import React from "react"

import Information from "../blocks/Information"
import CryptoBasicsCards from "./cryptoBasicsCards"
import MiddleSectionContainer from "../blocks/MiddleSectionContainer"
import { basics } from "./constants"
const App = () => (
  <MiddleSectionContainer id="crypto_basics">
    <Information>
      <Information.Title>Crypto basics</Information.Title>
      <Information.Content>
        Ready to embark on a journey to gain valuable knowledge on the subject
        of blockchain and cryptocurrency? Here’s the perfect starting point!
      </Information.Content>

      <CryptoBasicsCards data={basics} />
    </Information>
  </MiddleSectionContainer>
)

export default App
