import React from "react"

import Information from "../blocks/Information"
import CryptoBasicsCards from "./cryptoBasicsCards"
import MiddleSectionContainer from "../blocks/MiddleSectionContainer"
import { trading } from "./constants"

const App = () => (
  <MiddleSectionContainer id="trading_crypto">
    <Information>
      <Information.Title>Trading Crypto</Information.Title>
      <Information.Content>
        Ready to acquire your own crypto? Let’s help you get started on this
        journey!
      </Information.Content>

      <CryptoBasicsCards data={trading} />
    </Information>
  </MiddleSectionContainer>
)

export default App
