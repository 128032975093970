import React from "react"

import TradingCrypto from "../components/tradingCrypto"
import CryptoBasics from "../components/cryptoBasics"
import CryptoWallet from "../components/cryptoWallet"
import WhatsSpecial from "../components/whatsSpecial"
import AboutCrypto from "../components/aboutCrypto"
import SafetyTips from "../components/SafetyTips"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./styles.css"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Busha - Learn the Basics of Cryptocurrency"
      keywords={[
        "Busha",
        "Learn",
        "Crypto",
        "Bitcoin",
        "Ethereum",
        "Cryptocurrency",
      ]}
      description="Get answers to all your questions about cryptocurrency. Learn the basics of crypto trading"
    />
    <CryptoBasics />
    <WhatsSpecial />
    <AboutCrypto />
    <SafetyTips />
    <TradingCrypto />
    <CryptoWallet />
  </Layout>
)

export default IndexPage
