import React from "react"

import InformationCard from "../blocks/InformationCard"

class Card extends React.Component {
  state = {
    expand: false,
  }
  onPress = e => {
    e.preventDefault()
    e.stopPropagation()
    const { expand } = this.state
    const { onExpandAll } = this.props
    if (expand) {
      onExpandAll(-1)
    } else {
      onExpandAll(1)
    }
    this.setState(({ expand }) => ({ expand: !expand }))
  }
  componentDidUpdate(prevProps) {
    if (prevProps.isAllExpanded !== this.props.isAllExpanded) {
      if (this.props.isAllExpanded) {
        this.setState({ expand: true })
        this.props.onExpandAll("max")
      } else {
        this.setState({ expand: false })
        this.props.onExpandAll("min")
      }
    }
  }
  render() {
    const { expand } = this.state
    const { title, content, large } = this.props

    return (
      <InformationCard large={large} expand={expand} onClick={this.onPress}>
        <InformationCard.Header>
          <InformationCard.Title>{title}</InformationCard.Title>
          <InformationCard.Button onClick={this.onPress} expand={expand} />
        </InformationCard.Header>
        <InformationCard.Content expand={expand}>
          {content}
        </InformationCard.Content>
      </InformationCard>
    )
  }
}

export default Card
