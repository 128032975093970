import styled from "styled-components"

import Content from "./Content"
import Title from "./Title"

const Information = styled.section`
  width: 100%;
`

Information.Title = Title
Information.Content = Content

export default Information
