import React from "react"

import Information from "../blocks/Information"
import CryptoBasicsCards from "./cryptoBasicsCards"
import MiddleSectionContainer from "../blocks/MiddleSectionContainer"
import { tips } from "./constants"

const App = () => (
  <MiddleSectionContainer special={true} id="safety_tips">
    <Information>
      <Information.Title>Safety Tips</Information.Title>
      <Information.Content>
        As with traditional financial transactions, there are some risks
        involved. Let’s help you stay safe whilst navigating the crypto space.
      </Information.Content>

      <CryptoBasicsCards data={tips} />
    </Information>
  </MiddleSectionContainer>
)

export default App
