import React from "react"

import InformationCardContainer from "../blocks/InformationCardContainer"
import { FaPlusSquare, FaTimesCircle } from "react-icons/fa"
import { isEven } from "../utils/helpers"
import Card from "./card"

class Cards extends React.Component {
  state = {
    expand: false,
    allExpanded: 0,
  }
  onExpandAll = () => {
    this.setState(({ expand }) => ({ expand: !expand }))
  }
  onIncrementAll = n => {
    if (n === "max") {
      this.setState({
        allExpanded: this.props.data.length,
      })
    } else if (n === "min") {
      this.setState({
        allExpanded: 0,
      })
    } else {
      this.setState(({ allExpanded }) => ({
        allExpanded: Number(allExpanded) + Number(n),
      }))
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.allExpanded !== this.state.allExpanded) {
      if (this.state.allExpanded === this.props.data.length) {
        this.setState({ expand: true })
      } else if (this.state.allExpanded === 0) {
        this.setState({ expand: false })
      }
    }
  }
  render() {
    const { expand } = this.state
    const { data } = this.props
    return (
      <InformationCardContainer>
        <InformationCardContainer.ButtonContainer>
          <InformationCardContainer.Button onClick={this.onExpandAll}>
            {expand ? "CLOSE ALL" : "EXPAND ALL"}
            <InformationCardContainer.SpecialText>
              {expand ? <FaTimesCircle /> : <FaPlusSquare />}
            </InformationCardContainer.SpecialText>
          </InformationCardContainer.Button>
        </InformationCardContainer.ButtonContainer>
        {data.map(({ title, content }, key) => (
          <Card
            key={key}
            title={title}
            content={content}
            large={isEven(key)}
            isAllExpanded={expand}
            onExpandAll={this.onIncrementAll}
          />
        ))}
      </InformationCardContainer>
    )
  }
}

export default Cards
