import styled from "styled-components"

import Content from "./Content"
import Button from "./Button"
import Header from "./Header"
import Title from "./Title"

const InformationCard = styled.section`
  width: 100%;
  border: 1px solid #979797;
  border-radius: 2px;
  max-width: ${({ large }) => (large ? "729.3px" : "640px")};

  padding: 0 25px;
  position: relative;
  margin-top: 22px;
  background-color: white;
  cursor: pointer;
  &::before {
    content: "";
    position: absolute;
    top: 6px;
    border: 1px solid #979797;
    right: -5px;
    border-radius: 2px;
    width: 100%;
    height: 100%;
  }
`
InformationCard.Title = Title
InformationCard.Header = Header
InformationCard.Button = Button
InformationCard.Content = Content

export default InformationCard
