import styled, { css } from "styled-components"

const Container = styled.div`
  width: 100%;
  background-image: url("https://res.cloudinary.com/dwoc5fknz/image/upload/v1556112930/bg-icons.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-position: 80% 173px;
  padding-bottom: 71px;
  background-color: #ffffff;
  padding-top: 182px;
  ${({ special }) =>
    special &&
    css`
      padding-left: 30%;
      background-position: 0 373px;
      background-image: url("https://res.cloudinary.com/dwoc5fknz/image/upload/v1556178224/bg-icons-2.svg");
    `}
  @media (max-width: 768px) {
    padding-left: 0;
    padding-top: 120px;
  }
`
export default Container
