import React from "react"

import Information from "../blocks/Information"
import CryptoBasicsCards from "./cryptoBasicsCards"
import MiddleSectionContainer from "../blocks/MiddleSectionContainer"
import { about } from "./constants"
const App = () => (
  <MiddleSectionContainer id="more_about_crypto">
    <Information>
      <Information.Title>More About Crypto</Information.Title>
      <Information.Content>
        Just some additional information you might find helpful.
      </Information.Content>

      <CryptoBasicsCards data={about} />
    </Information>
  </MiddleSectionContainer>
)

export default App
