import React from "react"

import Information from "../blocks/Information"
import CryptoBasicsCards from "./cryptoBasicsCards"
import MiddleSectionContainer from "../blocks/MiddleSectionContainer"
import { wallets } from "./constants"
const App = () => (
  <MiddleSectionContainer special={true} id="crypto_wallets">
    <Information>
      <Information.Title>Crypto Wallets</Information.Title>
      <Information.Content>
        Let’s take a look at the different types of wallets that are available.
      </Information.Content>

      <CryptoBasicsCards data={wallets} />
    </Information>
  </MiddleSectionContainer>
)

export default App
